<template>
  <div class="multiview-market-tiers-control">
    <div class="multiview-market-tiers-table">
      <div
        :class="[
          'multiview-market-tiers-table__row',
          'multiview-market-tiers-table__row--header',
        ]"
      >
        <div class="multiview-market-tiers-table__cell">
          <div class="multiview-market-tiers-table__label">
            Limits
          </div>
        </div>
        <div
          v-for="column in tierColumns"
          :key="column.id"
          class="multiview-market-tiers-table__cell"
        >
          <div class="multiview-market-tiers-table__label">
            {{ column.shortLabel }}
          </div>
        </div>
      </div>
      <div
        v-if="activeRule"
        :class="[
          'multiview-market-tiers-table__row',
          'multiview-market-tiers-table__row--data',
        ]"
      >
        <div class="multiview-market-tiers-table__cell">
          <div class="multiview-market-tiers-table__state">
            <div
              :class="[
                'multiview-market-tiers-table__status',
                { 'multiview-market-tiers-table__status--active': activeRule.active },
              ]"
            />
            <div
              :class="[
                'multiview-market-tiers-table__label',
                { 'multiview-market-tiers-table__label--muted': activeRule.disabled },
              ]"
            >
              {{ activeRule.label }}
            </div>
          </div>
          <Tooltip
            v-if="activeRule.hint"
            class="multiview-market-tiers-table__tooltip"
            :text="activeRule.hint"
          >
            <Icon
              name="info"
            />
          </Tooltip>
        </div>
        <div
          v-for="column in tierColumns"
          :key="column.id"
          class="multiview-market-tiers-table__cell"
        >
          <MarketTiersInput
            :model-value="getTierValue(column.id, activeRule.field)"
            :disabled="activeRule.disabled"
            :has-no-value="!getTierValue(column.id, activeRule.field)"
            @update:model-value="(newValue) => updateTierValue(column.id, activeRule.field, newValue)"
          />
        </div>
      </div>
    </div>
    <div class="multiview-market-tiers-control__selector">
      <button
        class="multiview-market-tiers-control__selector-button"
        :disabled="isPreviousRuleDisabled"
        @click="setPreviousRule"
      >
        <Icon
          name="chevron-up"
        />
      </button>
      <button
        class="multiview-market-tiers-control__selector-button"
        :disabled="isNextRuleDisabled"
        @click="setNextRule"
      >
        <Icon
          name="chevron-down"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { cloneDeep, isEqual, values } from 'lodash';
import { createTierColumns, createStateRules } from '@/services/helpers/market-tiers';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';
import MarketTiersInput from './MarketTiersInput';

export default {
  components: {
    Tooltip,
    Icon,
    MarketTiersInput,
  },
  props: {
    matchState: {
      type: String,
      default: '',
    },
    eventId: {
      type: String,
      required: true,
    },
    limitConfiguration: {
      type: Object,
      required: true,
    },
    inPrematch: {
      type: Boolean,
      default: undefined,
    },
    inBreak: {
      type: Boolean,
      default: undefined,
    },
  },
  emits: {
    'update:limitConfiguration': {
      type: Object,
    },
    savedLimitConfiguration: {},
  },
  setup(props, { emit }) {
    const tiers = ref(cloneDeep(props.limitConfiguration?.tiers));
    const originalTiers = ref(cloneDeep(props.limitConfiguration?.tiers));
    const tierColumns = computed(() => createTierColumns());
    const stateRules = computed(() => createStateRules({
      matchState: props.matchState,
      inPrematch: props.inPrematch,
      inBreak: props.inBreak,
      hideIndicators: false,
    }));
    const containsChanges = computed(() => !isEqual(tiers.value, originalTiers.value));

    const getTierValue = (tierId, tierField) => (tiers.value ? tiers.value?.[tierId]?.[tierField] / 1000 : null);
    const updateTierValue = (tierId, tierField, newValue) => {
      const newTiers = cloneDeep(tiers.value);
      newTiers[tierId][tierField] = newValue * 1000;
      if (tierField === 'preMatch') newTiers[tierId].inBreak = newTiers[tierId][tierField];
      tiers.value = newTiers;
      emit('update:limitConfiguration', {
        ...props.limitConfiguration,
        tiers: newTiers,
      });
    };

    const allRules = computed(() => values(stateRules.value));
    const activeRuleIndex = ref(0);
    const activeRule = computed(() => allRules.value[activeRuleIndex.value]);
    const isPreviousRuleDisabled = computed(() => activeRuleIndex.value <= 0);
    const isNextRuleDisabled = computed(() => activeRuleIndex.value + 1 >= allRules.value.length);
    const setPreviousRule = () => {
      activeRuleIndex.value -= 1;
    };
    const setNextRule = () => {
      activeRuleIndex.value += 1;
    };

    watch(() => props.limitConfiguration, (newLimitValues) => {
      tiers.value = newLimitValues.tiers;
      originalTiers.value = newLimitValues.tiers;
    });

    return {
      tiers,
      originalTiers,
      tierColumns,
      stateRules,
      containsChanges,
      getTierValue,
      updateTierValue,
      allRules,
      activeRule,
      isPreviousRuleDisabled,
      setPreviousRule,
      isNextRuleDisabled,
      setNextRule,
    };
  },
};
</script>

<style lang="scss">
.multiview-market-tiers-control {
  display: flex;
  margin-top: 4px;

  .multiview-market-tiers-table {
    height: 48px;
    width: 100%;

    .multiview-market-tiers-table__row {
      display: grid;
      grid-template-columns: 115px 1fr 1fr 1fr 1fr;
      align-items: center;
      height: 24px;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0 8px;

      &.multiview-market-tiers-table__row--header {
        background: #FAFAFA;
        color: #6D6D6D;
      }

      &.multiview-market-tiers-table__row--data {
        background: #fff;
        color: #191414;

        .multiview-market-tiers-table__cell {
          border-bottom: 1px solid #F0F0F0;
        }
      }
    }

    .multiview-market-tiers-table__cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 24px;
      padding: 0 8px;
      flex: 1;

      .icon {
        max-width: 16px;
        max-height: 16px;
        min-width: 16px;
        min-height: 16px;
      }

      &:not(:first-of-type) {
        text-align: right;
        justify-content: flex-end;
      }
    }

    .multiview-market-tiers-table__label {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.multiview-market-tiers-table__label--muted {
        color: #A9A9A9;
      }
    }

    .multiview-market-tiers-table__state {
      display: flex;
      align-items: center;
      gap: 4px;

      .multiview-market-tiers-table__label {
        padding: 0;
      }
    }

    .multiview-market-tiers-table__status {
      display: block;
      border-radius: 100px;
      border: 2px solid #CDCDCD;
      background: #F0F0F0;
      width: 12px;
      height: 12px;

      &.multiview-market-tiers-table__status--active {
        background: #00BC57;
        border-color: #007134;
      }
    }

    .multiview-market-tiers-table__tooltip {
      padding: 8px;
    }
  }

  &__selector {
    display: flex;
    flex-direction: column;

    &-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      user-select: none;

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }

      .icon {
        width: 16px;
        height: 16px;
        stroke: #191414;
      }
    }
  }
}
</style>
